import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { MARKS } from '@contentful/rich-text-types';

import Typography from 'components/atoms/Typography';
import Container from 'components/molecules/Container';

import { useScreenService } from 'hooks/useScreenService';

import {
  StyledHomepageCircle,
  StyledImage,
  StyledMainContainer,
  StyledTypography,
} from './HomepageBanner.styles';

import { HomepageBannerType } from './models.d';

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => (
      <Typography asEl="span" className="gradient-text" data-text={text}>
        {text}
      </Typography>
    ),
  },
};

const HomepageBanner = ({
  description,
  image,
  mobileImage,
  circle,
  testId,
}: HomepageBannerType) => {
  const { isMobile, isMdUp } = useScreenService();

  return (
    <StyledMainContainer {...{ testId }}>
      <Container contentWidth={1280} gutter noPadding>
        {circle ? <StyledHomepageCircle image={circle.image} alt={circle.alt} /> : null}
        {description ? (
          <StyledTypography
            padding={{
              top: { base: 72, md: 80, lg: 101, xl: 184 },
              bottom: { base: 265, md: 180, xl: 330 },
            }}
            size={{ base: 60, md: 72, lg: 124, xl: 156 }}
            lineHeight={1.2}
            fontWeight={700}
            color="gray-900"
            letterSpacing="-0.02em"
          >
            {renderRichText(description, options)}
          </StyledTypography>
        ) : null}
        {isMdUp ? (
          <StyledImage image={image.image} alt={image.alt} loading="eager" objectFit="contain" />
        ) : null}
        {isMobile ? (
          <StyledImage
            image={mobileImage.image}
            alt={mobileImage.alt}
            loading="eager"
            objectFit="contain"
          />
        ) : null}
      </Container>
    </StyledMainContainer>
  );
};

export default HomepageBanner;
