import styled from 'styled-components';
import { breakpoint } from 'styles';

import Image from 'components/atoms/Image';
import Typography from 'components/atoms/Typography';
import Container from 'components/molecules/Container';

export const SpecialistBannerContainer = styled(Container)`
  position: relative;

  & > * {
    z-index: var(--z-index-ground);
  }

  &::before {
    position: absolute;
    inset: 0;
    z-index: 0;
    display: block;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 95%);
    content: '';
    background: linear-gradient(360deg, var(--c-white) -3.85%, var(--c-transparent) 100%);
    transform: translateY(100px);

    ${breakpoint.md} {
      clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 70%);
    }

    ${breakpoint.xl} {
      clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 85%);
    }
  }
`;

export const StyledSpecialistBanner = styled.div`
  display: grid;
  grid-template-rows: 230px repeat(2, auto) 1fr;

  ${breakpoint.md} {
    grid-template-rows: repeat(2, auto) 1fr;
    grid-template-columns: 263px 1fr;
  }

  ${breakpoint.lg} {
    grid-template-columns: 391px 1fr;
  }

  ${breakpoint.xl} {
    grid-template-columns: 630px 1fr;
  }
`;

export const StyledImage = styled(Image)`
  align-self: center;
  justify-self: center;

  ${breakpoint.md} {
    grid-row: 1/4;
    transform: scale(1.1);
    transform-origin: left;
  }

  ${breakpoint.xl} {
    transform: none;
  }
`;

export const StyledTagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-xxxxs);
  align-items: flex-start;
  z-index: var(--z-index-ground);

  ${breakpoint.lg} {
    gap: var(--gap-xxs);
    padding-bottom: var(--gap-lg);
  }

  ${breakpoint.xl} {
    padding-bottom: 104px;
  }
`;

export const StyledTag = styled(Typography)`
  background: linear-gradient(
    91.25deg,
    rgba(255, 255, 255, 75%) 0.71%,
    rgba(252, 253, 254, 0%) 100%
  );
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-primary) var(--c-opacity-blue-05);
`;
