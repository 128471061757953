import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import Container from 'components/molecules/Container';
import HomepageBanner from 'components/organisms/HomepageBanner/HomepageBanner';
import RelatedList, { RelatedListVariant } from 'components/organisms/RelatedList';
import { SpecialistBannerContainer } from 'components/organisms/SpecialistBanner/SpecialistBanner.styles';
import { TechnologiesSectionVariant } from 'components/organisms/TechnologiesSection';
import {
  StyledHomepageFeatureSectionContainer,
  StyledOpinionCircle,
  StyledOurCaseContainer,
  StyledOurCaseStudyCircle,
} from 'components/templates/containers';
import Layout from 'components/templates/Layout';

const Opinion = loadable(() => import('components/molecules/Opinion'));
const Statictics = loadable(() => import('components/molecules/Statictics'));
const SpecialistBanner = loadable(() => import('components/organisms/SpecialistBanner'));
const PhotoGallery = loadable(() => import('components/molecules/PhotoGallery'));
const TechnologiesSection = loadable(() => import('components/organisms/TechnologiesSection'));
const ServicesSection = loadable(() => import('components/organisms/ServicesSection'));

const HomePage = ({
  data: {
    contentfulHomePage: {
      banner,
      bannerImage,
      mobileBannerImage,
      technologies,
      relatedList,
      carousel,
      customerSatisfactionSection,
      featureSectionStructure,
      seo,
      statistics,
      specialistBanner,
      circle,
      bigCircle,
    },
  },
}: ServicesDetailPageTypes.HomePageProps) => (
  <Layout seo={seo} className="home-page">
    <HomepageBanner
      description={banner}
      mobileImage={mobileBannerImage}
      image={bannerImage}
      circle={circle}
    />
    {technologies ? (
      <TechnologiesSection {...technologies} variant={TechnologiesSectionVariant.HOMEPAGE} />
    ) : null}
    <SpecialistBannerContainer mb={{ base: 120, md: 200, xl: 240 }} contentWidth={1440}>
      <SpecialistBanner {...specialistBanner} />
    </SpecialistBannerContainer>
    <StyledHomepageFeatureSectionContainer mb={{ base: 120, lg: 200, xl: 240 }} contentWidth={1280}>
      <ServicesSection {...featureSectionStructure} />
    </StyledHomepageFeatureSectionContainer>
    <Container
      contentWidth={1280}
      mb={{ base: 48, md: 72, lg: 180 }}
      mt={{ base: 112, md: 200, lg: 240, xl: 280 }}
    >
      <Statictics {...{ statistics }} />
    </Container>
    <PhotoGallery {...carousel} />
    {customerSatisfactionSection?.quote.map(({ id, card, description }, idx) => (
      <Container
        key={`swiper-${id}-${idx}`}
        mt={{ base: 80, md: 184, lg: 200, xl: 280 }}
        gutter
        contentWidth={1280}
      >
        <StyledOpinionCircle
          image={customerSatisfactionSection.circleImage.image}
          alt={customerSatisfactionSection.circleImage.alt}
        />
        <Opinion key={`swiper-${id}-${idx}`} {...{ card, description }} />
      </Container>
    ))}
    <StyledOurCaseContainer
      pb={{ base: 80, md: 120, lg: 200, xl: 240 }}
      pt={{ base: 80, md: 144, lg: 160, xl: 240 }}
    >
      <StyledOurCaseStudyCircle image={bigCircle.image} alt={bigCircle.alt} />
      {relatedList.map((list, idx) => (
        <RelatedList
          key={idx}
          {...list}
          list={list.relatedSites}
          variant={RelatedListVariant.OUR_CASE_STUDY}
        />
      ))}
    </StyledOurCaseContainer>
  </Layout>
);

export default HomePage;

export const query = graphql`
  query {
    contentfulHomePage {
      seo {
        ...SeoFragment
      }
      pageName
      circle {
        ...ImageDataStructureFragment
      }
      bigCircle {
        ...ImageDataStructureFragment
      }
      banner {
        raw
      }
      mobileBannerImage {
        ...ImageDataStructureFragment
      }
      bannerImage {
        ...ImageDataStructureFragment
      }
      specialistBanner {
        ...SpecialistBannerFragment
      }
      relatedList {
        ...RelatedListFragment
      }
      featureSectionStructure {
        ...FeatureSectionFragment
      }
      carousel {
        ...PhotoGalleryFragment
      }
      technologies {
        ...TechnologiesSectionFragment
      }
      statistics {
        ...StatisticsFragment
      }
      customerSatisfactionSection {
        title
        circleImage {
          ...ImageDataStructureFragment
        }
        quote {
          id
          description {
            raw
          }
          card {
            title
            subtitle
            image {
              ...ImageDataStructureFragment
            }
          }
        }
      }
    }
  }
`;
