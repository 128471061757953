import styled from 'styled-components';
import { breakpoint } from 'styles';

import Image from 'components/atoms/Image';
import Typography from 'components/atoms/Typography';
import Container from 'components/molecules/Container';

export const StyledHomepageCircle = styled(Image)`
  --circle-width: 300px;

  position: absolute;
  top: 0;
  left: 0;
  width: var(--circle-width);
  height: var(--circle-width);
  transform: translate(-50%, calc(var(--header-height-mobile) * -1));

  ${breakpoint.md} {
    --circle-width: 400px;
  }

  ${breakpoint.lg} {
    transform: translate(-50%, calc(var(--header-height-desktop) * -1));
    --circle-width: 460px;
  }

  ${breakpoint.xl} {
    transform: translate(-50%, -20%);
    --circle-width: 760px;
  }
`;

export const StyledMainContainer = styled(Container)`
  position: relative;
  padding-top: var(--header-height-mobile);
  margin-top: calc(-1 * var(--header-height-mobile));

  ${breakpoint.lg} {
    padding-top: var(--header-height-desktop);
    margin-top: calc(-1 * var(--header-height-desktop));
  }
`;

export const StyledTypography = styled(Typography)`
  z-index: var(--z-index-two);
  max-width: 450px;

  ${breakpoint.md} {
    max-width: 500px;
  }

  ${breakpoint.lg} {
    max-width: 900px;
  }

  ${breakpoint.xl} {
    max-width: 1050px;
  }

  .gradient-text {
    position: relative;
    display: inline-block;

    @supports (-webkit-text-stroke: 1px var(--c-gray-900)) {
      -webkit-text-stroke: 2px var(--c-gray-900);
      -webkit-text-fill-color: var(--c-white);
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      pointer-events: none;
      content: attr(data-text);
      background: linear-gradient(
        90deg,
        #00c1ec -3.99%,
        #00e9c6 45.99%,
        #e335ff 81.17%,
        #f90367 122.69%,
        #8002eb 170.7%,
        #403bf1 218.71%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    &:hover:not(.scrolled)::after {
      display: inline-block;
      clip-path: ellipse(62px 62px at var(--x) var(--y));

      @media (hover: none) {
        display: none;
      }
    }
  }
`;

export const StyledImage = styled(Image)`
  position: absolute;
  right: -80px;
  bottom: 0;
  z-index: var(--z-index-zero);
  width: 364px;
  max-width: unset;
  height: auto;

  img {
    object-position: right;
  }

  ${breakpoint.sm} {
    width: auto;
    height: 320px;
  }

  @media screen and (min-width: 560px) {
    height: 400px;
  }

  ${breakpoint.md} {
    right: auto;
    bottom: -50px;
    left: 420px;
    width: 560px;
    height: auto;
    max-height: unset;
  }

  ${breakpoint.lg} {
    bottom: auto;
    left: 640px;
    width: 780px;
  }

  ${breakpoint.xl} {
    bottom: 50px;
    left: 800px;
    width: 1000px;
  }
`;
